import { SentryConfigOptions } from '@/composables';

export type Configuration = {
  cognito?: {
    userPoolId: string;
    userPoolClientId: string;
  };
  portalApi: string;
  mapbox: {
    token?: string;
    overlaysTileset?: string;
    overlaysCentresTileset?: string;
  };
  remoteHazardAssets: {
    iconAssetsPathSvg: string;
    iconAssetsPathPng: string;
  };
  geospatialBucket: string;
  windTileBaseUrl: string;
  externalTileBaseUrl: string;
  webTemplateStyleSheetUrl: string;
  sentry?: SentryConfigOptions;
  environment: Environment;
};

export type Environment = (typeof ENVIRONMENTS)[number];

export const ENVIRONMENTS = [
  'local',
  'dev',
  'uat',
  'preflight',
  'preflight-uat',
  'preflight-dev',
  'prd-gv',
  'prd-training',
  'prd',
] as const;
export const DEFAULT_ENVIRONMENT: Environment = 'prd';
export const CLIENT_APP = 'ewa';
export const PARTITION_KEY = 'x-gv-client';

export const HOSTNAMES_TO_ENVIRONMENTS: Record<string, Environment> = {
  // !Production hostnames. Please DO NOT TOUCH these unless you know what you are doing.
  // !Refer to the infra/cfg/au-prd-config.ts.

  // Phase 1
  'emergency.wa.gov.au': 'prd',
  'www.emergency.wa.gov.au': 'prd',

  'training.ewa.wa.gov.au': 'prd-training',
  'www.training.ewa.wa.gov.au': 'prd-training',

  'emergencywa.genvis.com': 'prd-gv',
  'www.emergencywa.genvis.com': 'prd-gv',

  // !Preflight hostnames.
  'ewa.preflight.portal.genvis.com': 'preflight',
  'www.ewa.preflight.portal.genvis.com': 'preflight',

  'uat.ewa.wa.gov.au': 'preflight-uat',
  'www.uat.ewa.wa.gov.au': 'preflight-uat',

  'dev.ewa.wa.gov.au': 'preflight-dev',
  'www.dev.ewa.wa.gov.au': 'preflight-dev',

  // !UAT hostnames.
  'ewa.uat.portal.genvis.com': 'uat',
  'www.ewa.uat.portal.genvis.com': 'uat',

  // !Development hostnames.
  'ewa.dev.portal.genvis.com': 'dev',
  'www.ewa.dev.portal.genvis.com': 'dev',

  // !Local hostnames.
  localhost: 'local',
};
