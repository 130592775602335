import { Configuration } from './types';

/**
 * Internal DEV environment configuration.
 * This points at the DEV environment (i.e. all services referenced below are running/hosted in the DEV environment).
 */
export const config: Partial<Configuration> = {
  portalApi: 'https://ewa.api.dev.portal.genvis.com/v1',
  remoteHazardAssets: {
    iconAssetsPathSvg: 'https://dev.kudo.genvis.com/assets/icons/map/pins',
    iconAssetsPathPng: 'https://dev.kudo.genvis.com/assets/icons/map/pins/40x40',
  },
  geospatialBucket: 'https://d1ro7pgfug9vfo.cloudfront.net',
  windTileBaseUrl: 'https://d32hjt8pptb5el.cloudfront.net/',
  externalTileBaseUrl: 'https://decbn2efquael.cloudfront.net/',
  webTemplateStyleSheetUrl: 'https://dev.kudo.genvis.com/assets/styles/ewa-webtemplate.css',
  environment: 'dev',
};
