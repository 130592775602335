import tilebelt from '@mapbox/tilebelt';

export type TileLayer =
  | 'rain-radar'
  | 'weather-satellite'
  | 'cyclone-tracker'
  | 'thunderstorm-tracker'
  | 'wind-direction'
  | 'flood-catchment';

const TileLayerToId: Record<TileLayer, string> = {
  'rain-radar': 'IDR00010',
  'weather-satellite': 'IDE00435',
  'cyclone-tracker': 'Tropical_Cyclone',
  'thunderstorm-tracker': 'IDZ73094',
  'wind-direction': 'IDZ73006,IDZ73089',
  'flood-catchment': 'IDM00017',
};

export const buildLoadTileHandler = ({
  baseUrl,
  layer,
  transparent,
}: {
  baseUrl: string;
  layer: TileLayer;
  transparent: boolean;
}): ((tileID: { z: number; x: number; y: number }, options: { signal: AbortSignal }) => Promise<HTMLImageElement>) => {
  const layers = TileLayerToId[layer];

  return async (tileID, options) => {
    const bbox = tilebelt.tileToBBOX([tileID.x, tileID.y, tileID.z]);

    const url = new URL(baseUrl);

    url.searchParams.set('service', 'WMS');
    url.searchParams.set('request', 'GetMap');
    url.searchParams.set('layers', layers);
    url.searchParams.set('format', 'image/png');
    url.searchParams.set('transparent', transparent.toString());
    url.searchParams.set('version', '1.1.1');
    url.searchParams.set('width', '256');
    url.searchParams.set('height', '256');
    url.searchParams.set('srs', 'EPSG:4326');
    url.searchParams.set('styles', '');
    url.searchParams.set('bbox', bbox.join(','));

    const headers = new Headers();
    headers.set('Accept', 'image/png');

    const response = await fetch(url.toString(), {
      headers,
      signal: options.signal,
    });

    const blob = URL.createObjectURL(await response.blob());

    return await new Promise((resolve) => {
      const image = new Image();

      image.onload = () => {
        URL.revokeObjectURL(blob);
        resolve(image);
      };

      image.src = blob;
    });
  };
};
