import { Configuration } from './types';

export const config: Partial<Configuration> = {
  portalApi: 'https://api.uat.ewa.wa.gov.au/v1',
  remoteHazardAssets: {
    iconAssetsPathSvg: 'https://preflight.kudo.genvis.com/assets/icons/map/pins',
    iconAssetsPathPng: 'https://preflight.kudo.genvis.com/assets/icons/map/pins/40x40',
  },
  geospatialBucket: 'https://dbqyqh98zsjbu.cloudfront.net',
  windTileBaseUrl: 'https://d2gpn6hl4gh0ni.cloudfront.net/',
  externalTileBaseUrl: 'https://decbn2efquael.cloudfront.net/',
  webTemplateStyleSheetUrl: 'https://preflight.kudo.genvis.com/assets/styles/ewa-webtemplate.css',
  sentry: {
    dsn: 'https://cf40a211f5f4e6805072722487e2373b@o4507253793816576.ingest.de.sentry.io/4507255077339216',
  },
  environment: 'preflight-uat',
};
