<template>
  <div v-if="!bannerShown && !isThirtyDayAgo" class="fixed bottom-0 z-toasts block w-full bg-[#5b7699] p-4 md:bottom-12">
    <div class="flex flex-row justify-between">
      <div class="grow text-center text-base font-semibold text-white">
        <p>
          Our Terms of Use have been updated.
          <a href="/about#terms" class="underline" @click="permanentlyHideBanner">Review the changes</a>
        </p>
      </div>
      <button @click="hideBanner">
        <gv-icon name="close" class="fill-white" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { bannerUpdateTimestamp } from './constants';

const bannerShown = ref(false);

const hideBanner = () => {
  const expiration = Date.now() + 24 * 60 * 60 * 1000;
  localStorage.setItem('bannerShown', JSON.stringify({ type: 'temporary', expiration }));
  bannerShown.value = true;
};

const permanentlyHideBanner = () => {
  localStorage.setItem('bannerShown', JSON.stringify({ type: 'permanent' }));
  bannerShown.value = true;
};

const isThirtyDayAgo = computed(() => {
  const thirtyDays = 30 * 24 * 60 * 60;
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return currentTimestamp - bannerUpdateTimestamp > thirtyDays;
});

onMounted(() => {
  const storedValue = JSON.parse(localStorage.getItem('bannerShown') || '{}');

  bannerShown.value = storedValue.type === 'permanent' || (storedValue.type === 'temporary' && Date.now() < storedValue.expiration);
});
</script>
